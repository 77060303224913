<template>
  <div class="allmsgclass">
    <div
      v-show="fileArray.length > 0 || otherFileArray.length > 0"
      class="updivclass"
    >
      <div
        style="margin-bottom: 0.3rem"
        v-for="(item, index) in fileArray"
        v-bind:key="index"
      >
        <div class="fileclass">
          <div>{{ item.name }}</div>
          <div>{{ item.size }}</div>
        </div>
        <div style="color: #67c23a">成功：{{ item.title }}</div>
      </div>
      <div
        class="otherclass"
        v-for="(item, index) in otherFileArray"
        v-bind:key="index + '1'"
      >
        <div class="fileclass2">
          <div>{{ item.name }}</div>
          <div>{{ item.size }}</div>
        </div>
        <div style="color: #ff5f18">失败：原因{{ item.title }}</div>
      </div>
    </div>
    <div
      v-show="fileArray.length < 1 && otherFileArray.length < 1"
      class="updivclass"
    >
      <div class="yuanclass">
        <div>
          <div class="">
            <van-circle
              size="4rem"
              :layer-color="'#f6f6f7'"
              :clockwise="false"
              v-model="currentRate"
              :stroke-width="100"
              :rate="currentRate2"
              :speed="1000"
              :text="text"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-show="fileArray.length < 1 && otherFileArray.length < 1"
      class="selectclass2 se2"
    >
      <div>识别中</div>
      <van-loading size=".3rem" type="spinner" />
    </div>
    <div
      @click.stop="clearmeth"
      v-show="fileArray.length > 0 || otherFileArray.length > 0"
      class="selectclass2"
    >
      完成
    </div>
    <div style="height: 0.5rem"></div>
  </div>
</template>
<script>
import base from "../../util/base";
import eventbus from "../../util/eventbus";

export default {
  data() {
    return {
      currentRate: 100,
      currentRate2: 0,
      uploadshow2: false,
      settime: "",
      fileArray: [],
      otherFileArray: [],
      fileArray2: [],
    };
  },
  computed: {
    text() {
      return this.currentRate.toFixed(0) + "%";
    },
  },
  created() {
    eventbus.$off("shibie");
    eventbus.$on("shibie", (e) => {
      clearTimeout(this.settime);
      this.settime = setTimeout(() => {
        this.fileArray2 = e.fileArray;
        this.sub_id = e.sub_id;
        this.selectGangwei();
      });
    });
    eventbus.$on("shibie_init", () => {
      this.fileArray = [];
      this.otherFileArray = [];
      this.currentRate2 = 0;
      this.settime = null;
    });
  },
  mounted() {
    eventbus.$emit("shibieload");
  },
  methods: {
    clearmeth() {
      eventbus.$emit("clear_shibie");
    },
    selectGangwei() {
      let that = this,
        arr = [],
        arr2 = [];
      that.fileArray = [];
      that.otherFileArray = [];
      this.fileArray2.forEach((e) => {
        let formData = new FormData();
        formData.append("file", e.files);
        formData.append("requestParams[sub_id]", this.sub_id);
        formData.append("requestParams[reqType]", "resume");
        formData.append("user_id", localStorage.getItem("userId")),
          formData.append("accessToken", localStorage.getItem("accessToken"));
        formData.append("token", localStorage.getItem("token"));
        let xhr = new XMLHttpRequest();
        xhr.open("post", base.formal + "/firm/v1/Batch_upload/uploads", true);
        xhr.onreadystatechange = () => {
          if (xhr.readyState == 4) {
            try {
              that.currentRate2 += 100 / Array.from(that.fileArray2).length;
              let msg = JSON.parse(xhr.responseText);
              console.log(msg);
              if (msg.msg == "success") {
                e.title = "识别成功";
                arr.push(e);
              } else {
                e.title = "识别失败";
                arr2.push(e);
              }
              if (this.currentRate2 > 100 || this.currentRate2 == 100) {
                console.log(this.fileArray);
                this.fileArray = arr;
                this.otherFileArray = arr2;
              }
            } catch {
                that.clearmeth();
                that.$toast("服务器出错");
            }
          }
        };
        xhr.send(formData);
      });
    },
  },
};
</script>
<style scoped>
.se2 {
  background: rgb(103, 104, 104) !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.se2 > :first-child {
  margin-right: 0.2rem;
}
.yuanclass {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.seclass {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(74, 74, 75) !important;
}
.upclass .selectclassthe >>> .van-popup {
  width: 100%;
}
.upmethclass {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  justify-content: center;
}
.upmethclass > :first-child {
  margin-right: 0.3rem;
}
.otherclass {
  margin-top: 0.3rem;
}
.fileclass {
  display: flex;
  align-items: center;
}
.fileclass > :first-child {
  margin-right: 0.3rem;
}
.fileclass2 {
  display: flex;
  align-items: center;
}
.fileclass2 > :first-child {
  margin-right: 0.3rem;
}
.allmsgclass {
  height: 100%;
  position: relative;
}
.updivclass {
  padding: 5%;
  height: 65%;
  overflow: auto;
  border: 1px solid rgb(228, 226, 226);
  font-size: 0.3rem;
  position: relative;
}
.selectclass {
  background: linear-gradient(90deg, #ff8d35, #ff5f18);
  padding: 0.2rem 0;
  color: white;
  font-size: 0.3rem;
  width: 3rem;
  text-align: center;
  border-radius: 0.1rem;
}
.selectclass2 {
  background: linear-gradient(90deg, #ff8d35, #ff5f18);
  padding: 0.2rem 0;
  color: white;
  font-size: 0.3rem;
  width: 3rem;
  text-align: center;
  border-radius: 0.1rem;
  margin-left: 50%;
  transform: translate(-50%);
  margin-top: 0.5rem;
}
#upfileid {
  opacity: 0;
}
</style>