<template>
  <div class="allmsgclass">
    <navigation msg="简历助手">
      <template>
        <div @click="uploadshow = true" class="temclass">
          <van-icon size=".45rem" name="search" />
          <div>识别简历</div>
        </div>
      </template>
    </navigation>
    <div v-if="ishavemsg" class="vantab">
      <van-tabs swipe-threshold="3" @click="clicktab">
        <van-tab
          v-for="(item, index) in tablist"
          v-bind:key="index"
          :title="item.name"
        >
        </van-tab>
      </van-tabs>
    </div>
    <div v-for="(item, index) in allmsg" v-bind:key="index" class="themsgclass">
      <!-- 识别成功 -->
      <div v-if="item.is_success == 1">
        <img
          class="yesclass"
          src="../../assets/resumeassistant_yes.png"
          alt=""
        />
        <div class="topmsg">
          <div @click="seefile(item.file_url)" class="mm16">
            <img
              class="mm17"
              src="../../assets/resumeassistant_t1.png"
              alt=""
            />
            <div>查看附件</div>
          </div>
          <div @click="updateGangwei(item.id)" class="mm16">
            <div>切换岗位</div>
            <img
              class="mm18"
              src="../../assets/resumeassistant_t2.png"
              alt=""
            />
          </div>
        </div>
        <div class="mm1">
          <div class="mm2">
            <img
              v-if="(item.sex + '').indexOf('男') != -1"
              class=""
              src="../../assets/user_1.png"
              alt=""
            />
            <img v-else class="" src="../../assets/user_2.png" alt="" />
            <div>
              <div class="mm3">{{ item.name }}</div>
              <div class="mm4">获取时间：{{ item.add_time }}</div>
              <div class="mm5">
                来源：{{ item.source == 2 ? "萝卜猎手" : "第三方" }}
              </div>
            </div>
          </div>
          <div class="mm6">
            <div>{{ (item.final_sim * 10).toFixed(2) }}%</div>
            <div>适配度</div>
          </div>
        </div>
        <div
          :style="
            item.isshow == true
              ? 'height:0;overflow:hidden;margin-top:0;'
              : 'height:auto;margin-top:.66rem;'
          "
          class="mm7"
        >
          <div
            @click="clickmenu({ state: 1, item: item, type: 1 })"
            class="mm11"
          >
            <img
              v-if="item.is_pay == 0"
              class="mm15"
              src="../../assets/resumeassistant_7.png"
              alt=""
            />
            <img
              v-if="item.is_pay == 1"
              class="mm15"
              src="../../assets/resumeassistant_8.png"
              alt=""
            />
            <div>
              <img
                class="mm12"
                src="../../assets/resumeassistant_1.png"
                alt=""
              />
            </div>
            <div class="mm13">
              <div>能力分析</div>
              <img
                @click.stop="
                  showtip('三秒钟多维度解读简历，TA的优势、特长、标签一目了然')
                "
                src="../../assets/resumeassistant_6.png"
                alt=""
              />
            </div>
          </div>
          <div
            @click="clickmenu({ state: 2, item: item, type: 2 })"
            class="mm11"
          >
            <img
              v-if="item.is_pay1 == 0"
              class="mm15"
              src="../../assets/resumeassistant_7.png"
              alt=""
            />
            <img
              v-if="item.is_pay1 == 1"
              class="mm15"
              src="../../assets/resumeassistant_8.png"
              alt=""
            />
            <div>
              <img
                class="mm12"
                src="../../assets/resumeassistant_2.png"
                alt=""
              />
            </div>
            <div class="mm13">
              <div>胜任力匹配</div>
              <img
                @click.stop="
                  showtip('TA和您的岗位要求匹配度如何？算一算，面试更精准！')
                "
                src="../../assets/resumeassistant_6.png"
                alt=""
              />
            </div>
          </div>
          <div
            @click="clickmenu({ state: 3, item: item, type: 3 })"
            class="mm11"
          >
            <div>
              <img
                class="mm12"
                src="../../assets/resumeassistant_3.png"
                alt=""
              />
            </div>
            <div class="mm13">
              <div>发起背调</div>
              <img
                @click.stop="
                  showtip(
                    '初面或二面前进行身份核验，提高面试效率；入职前完成全面背调，雇佣更安全。'
                  )
                "
                src="../../assets/resumeassistant_6.png"
                alt=""
              />
            </div>
          </div>
          <div
            @click="clickmenu({ state: 4, item: item, type: 4 })"
            class="mm11"
          >
            <div>
              <img
                class="mm12"
                src="../../assets/resumeassistant_4.png"
                alt=""
              />
            </div>
            <div class="mm13">
              <div>问卷测评</div>
              <img
                @click.stop="showtip('我们正在快马加鞭开发中，敬请期待～')"
                src="../../assets/resumeassistant_6.png"
                alt=""
              />
            </div>
          </div>
          <div
            @click="clickmenu({ state: 5, item: item, type: 5 })"
            class="mm11"
          >
            <div>
              <img
                class="mm12"
                src="../../assets/resumeassistant_5.png"
                alt=""
              />
            </div>
            <div class="mm13">
              <div>邀请面试</div>
              <img
                @click.stop="showtip('我们正在快马加鞭开发中，敬请期待～')"
                class="mm14"
                src="../../assets/resumeassistant_6.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="mm8">
          <div @click="zjsmeth(item)" class="mm9">
            <div>帮TA转介绍</div>
            <img
              @click.stop="
                showtip(
                  '如候选人和当前岗位需求不合适，帮TA转介绍给其他更多企业，为了感谢您的举荐，送您余额奖励哦！'
                )
              "
              src="../../assets/resumeassistant_6.png"
              alt=""
            />
          </div>
          <div @click="zsmeth(item.isshow, index)" class="mm10">
            <div>{{ item.isshow == true ? "展开" : "收起" }}</div>
            <van-icon v-show="!item.isshow" name="arrow-up" />
            <van-icon v-show="item.isshow" name="arrow-down" />
          </div>
        </div>
      </div>
      <!-- 识别失败 -->
      <div v-else>
        <img
          class="yesclass"
          src="../../assets/resumeassistant_no.png"
          alt=""
        />
        <div class="topmsg">
          <div class="mm16">
            <img
              class="mm17"
              src="../../assets/resumeassistant_t1.png"
              alt=""
            />
            <div>查看附件</div>
          </div>
          <div class="mm16">
            <div>切换岗位</div>
            <img
              class="mm18"
              src="../../assets/resumeassistant_t2.png"
              alt=""
            />
          </div>
        </div>
        <div class="mm1">
          <div class="mm2">
            <img
              v-if="(item.sex + '').indexOf('女') != -1"
              class=""
              src="../../assets/user_2.png"
              alt=""
            />
            <img v-else class="" src="../../assets/user_2.png" alt="" />

            <div>
              <div class="mm3">*****</div>
              <div class="mm4">获取时间：{{ item.add_time }}</div>
              <div class="mm5">来源：第三方</div>
            </div>
          </div>
          <div class="mm6">
            <div>***</div>
            <div>适配度</div>
          </div>
        </div>
        <div class="mm8">
          <div class="mm9">
            <div>帮TA转介绍</div>
            <img
              @click.stop="
                showtip(
                  '如候选人和当前岗位需求不合适，帮TA转介绍给其他更多企业，为了感谢您的举荐，送您余额奖励哦！'
                )
              "
              src="../../assets/resumeassistant_6.png"
              alt=""
            />
          </div>
          <div class="mm10"></div>
        </div>
      </div>
    </div>

    <div v-if="!ishavemsg" class="nomsgclass">
      <div class="nomsgclassimg">
        <img src="../../assets/nom.png" alt="" />
      </div>
      <div class="no1">您的还没有简历哦~</div>
      <div class="no2">完成岗位创建，即可上传简历测试匹配度</div>
      <div class="buttonclass">
        <div @click="$router.push('/resume?resumeindex=3')">去人才库转转</div>
        <div @click="$router.push('/dingyue')">去设置岗位</div>
      </div>
      <div class="tipbottom">电脑登录cop.luobolieshou.com畅享完美体验</div>
    </div>
    <div class="tipclass">
      <van-popup v-model="show">{{ tipmsg }}</van-popup>
    </div>

    <!-- 转介绍 -->
    <van-popup
      v-model="showJS"
      round
      position="bottom"
      :style="{ height: '50%' }"
    >
      <div class="js1">完善候选人信息</div>
      <div class="zjsclass">
        <van-field
          :required="true"
          :readonly="zjs_name?true:false"
          v-model="zjs_name"
          label="姓名"
          placeholder="输入姓名"
        />
        <van-field
          :required="true"
          readonly
          clickable
          label="性别"
          :value="zjs_sex"
          placeholder="选择性别"
          @click="showsex = true"
        />
        <!-- 输入手机号，调起手机号键盘 -->
        <van-field
          :required="true"
          v-model="zjs_phone"
          :readonly="zjs_phone?true:false"
          type="tel"
          label="手机号"
          placeholder="输入手机号"
        />
        <div class="liclass">
          <van-field :required="true" readonly clickable label="推荐理由" />
        </div>

        <div class="liyouall">
          <div
            :style="
              item.isselect
                ? 'background-color: rgba(254,89,3,.06);color:#ff4500'
                : ''
            "
            v-for="(item, index) in reasonList"
            @click="selectReason(index, item)"
            v-bind:key="index"
            class="liyouclass"
          >
            {{ item.tag }}
          </div>
          <div class="liyouclass2"></div>
          <div class="liyouclass2"></div>
        </div>
        <div class="js2">
          <span @click="replacemeth">换一批</span>｜<span @click="selectall"
            >全选</span
          >
        </div>
        <div class="js3">
          <div class="js4" @click="showJS = false">取消</div>
          <div class="js5" @click="upzjs(zjs_id)">转介绍</div>
        </div>
        <div style="height: 0.3rem"></div>
      </div>
    </van-popup>
    <!-- 选择岗位 -->
    <van-action-sheet
      v-model="selectShow"
      :actions="tablist"
      cancel-text="取消"
      close-on-click-action
      @select="selectGangwei"
    />
    <van-action-sheet
      v-model="showsex"
      :actions="[{ name: '保密' }, { name: '男' }, { name: '女' }]"
      @select="selectsex"
    />
    <div v-if="ishavemsg" @click="$router.push('/dingyue')" class="addgangwei">
      <div>
        <van-icon name="plus" color="#FF6D20" size=".45rem" />
        <div>创建岗位</div>
      </div>
    </div>
    <loading @loaddom="loaddom" ref="loades"></loading>
    <div class="upclass">
      <van-popup v-if="uploadshow" v-model="uploadshow">
        <uploadresume></uploadresume>
      </van-popup>
      <van-popup @close="shibie_close" v-model="uploadshow2">
        <uploadresume2 :msg="shibiemsg"></uploadresume2>
      </van-popup>
    </div>
    <div style="height: 1.3rem"></div>
    <!-- 购买简历 -->
    <van-dialog
      @confirm="buytwo"
      @cancel="buyone"
      :cancel-button-text="'使用余额3'"
      :confirm-button-text="'使用余额6'"
      :show-cancel-button="true"
      v-model="showDialog"
      title="提示"
    >
      <div style="padding: 0.5rem 0.3rem">
        完成简历能力分析，可以提高胜任力匹配的精确度哦~使用余额6<span
          style="color: #ee0a24"
          >一键查看全部报告</span
        >;使用余额3<span style="color: #ee0a24">只查看能力分析报告</span>
      </div>
    </van-dialog>
  </div>
</template>
<script>
import uploadresume from "../../components/uploadresume.vue";
import uploadresume2 from "../../components/uploadresume2.vue";
import loading from "../../components/loading2/index";
import { Dialog, Toast } from "vant";
import eventbus from "../../../util/eventbus";
export default {
  components: {
    loading,
    uploadresume,
    uploadresume2,
  },
  data() {
    return {
      tablist: [],
      ishavemsg: true,
      pageno: 0,
      sub_id: "",
      allmsg: [],
      show: false,
      tipmsg: "",
      selectShow: false,
      id: "",
      sub_id: "",
      showJS: false,
      showDialog: false,
      zjs_name: "",
      zjs_phone: "",
      zjs_sex: "",
      zjs_id: "",
      reasonList: [], //推荐理由
      showsex: false,
      uploadshow: false,
      uploadshow2: false,
      shibiemsg: {},
      shibieMSg: {},
      shibieload: false,
      settime: null,
      tableindex: 0,
      buyMsg: {},
    };
  },
  created() {
    this.getgangwei();
    //获取推荐理由
    this.getReason();
    eventbus.$on("clear_shibie", () => {
      this.uploadshow2 = false;
    });
    eventbus.$on("shibieload", () => {
      this.shibieload = true;
      clearTimeout(this.settime);
      this.settime = setTimeout(() => {
        eventbus.$emit("shibie", this.shibieMSg);
      }, 1);
    });
    eventbus.$on("parentmeth", (e) => {
      this.uploadshow = false;
      this.shibieMSg = e;
      this.uploadshow2 = true;
    });
  },
  methods: {
    shibie_close() {
      this.clicktab(this.tableindex);
    },
    //保存转介绍
    upzjs(id) {
      let intro = [];
      this.reasonList.forEach((e) => {
        if (e.isselect) {
          intro.push(e.tag);
        }
      });
      if (
        !this.zjs_name ||
        !this.zjs_phone ||
        !this.zjs_sex ||
        intro.length < 1
      ) {
        this.$toast("请完善必填信息");
        return;
      }
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "请稍后...",
      });
      this.$http
        .post("/firm/v1/talent_center/uploadTalentPool", {
          reqType: "resume",
          id: id,
          name: this.zjs_name,
          mobile: this.zjs_phone,
          sex: this.zjs_sex,
          introduce: intro.join(","),
        })
        .then(() => {
          Toast.clear();
          this.$toast("转介绍成功");
          this.showJS = false;
          this.clicktab(this.tableindex);
        });
    },
    //选择性别
    selectsex(e) {
      this.zjs_sex = e.name;
      this.showsex = false;
    },
    //全选推荐理由
    selectall() {
      for (let i = 0; i < this.reasonList.length; i++) {
        this.reasonList[i].isselect = true;
        this.$set(this.reasonList, i, this.reasonList[i]);
      }
    },
    //点击推荐理由
    selectReason(index, item) {
      item.isselect = !item.isselect;
      this.$set(this.reasonList, index, item);
    },
    //换一批推荐理由
    replacemeth() {
      this.getReason();
    },
    //转介绍
    zjsmeth(e) {
      this.showJS = true;
      this.zjs_name = e.name;
      this.zjs_phone = e.mobile;
      this.zjs_sex = e.sex ? e.sex : "保密";
      this.zjs_id = e.id;
    },
    //获取推荐理由
    getReason() {
      this.$http
        .post("/firm/v1/batch_upload/resumeReason", {
          reqType: "product",
        })
        .then((res) => {
          this.reasonList = JSON.parse(res.data).data.reasonList;
        });
    },
    onConfig() {},
    //切换岗位
    updateGangwei(id) {
      this.id = id;
      this.selectShow = true;
    },
    //选择岗位
    selectGangwei(e) {
      this.sub_id = e.id;
      this.toupdateMeth();
    },
    //调用切换岗位接口
    toupdateMeth() {
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "请稍后...",
      });
      this.$http
        .post("/firm/v1/talent_center/changePosts", {
          reqType: "resume",
          subId: this.sub_id,
          id: this.id,
        })
        .then(() => {
          Toast.clear();
          this.$toast("切换成功");
          this.$router.go(0);
        });
    },
    //查看附件
    seefile(msg) {
      if (msg) {
        window.location.href = msg;
      } else {
        this.$toast("该简历无附件");
      }
    },
    //展开收起
    zsmeth(isshow, index) {
      this.allmsg[index].isshow = !isshow;
      this.$set(this.allmsg, index, this.allmsg[index]);
    },
    //购买分析
    buyresume(msg, title, titlemsg) {
      let that = this;
      Dialog.confirm({
        title: title,
        message: titlemsg,
      })
        .then(() => {
          Toast.loading({
            duration: 0, // 持续展示 toast
            forbidClick: true,
            message: "请稍后...",
          });
          this.$http
            .post("/firm/v1/talent_center/payUploadResume", {
              reqType: "resume",
              id: msg.item.id,
              type: msg.type,
            })
            .then(() => {
              Toast.clear();
              this.$toast("支付成功");
              setTimeout(() => {
                if (msg.type == 1) {
                  that.resumeEvaluationmeth(msg);
                } else if (msg.type == 2) {
                  that.resumematchmeth(msg);
                }
              }, 500);
            });
        })
        .catch(() => {});
    },
    buyone() {
      let msg = this.buyMsg.msg;

      let that = this;
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "请稍后...",
      });
      this.$http
        .post("/firm/v1/talent_center/payUploadResume", {
          reqType: "resume",
          id: msg.item.id,
          type: 2,
        })
        .then(() => {
          Toast.clear();
          this.$toast("支付成功");
          setTimeout(() => {
            if (msg.type == 1) {
              that.resumeEvaluationmeth(msg);
            } else if (msg.type == 2) {
              that.resumematchmeth(msg);
            }
          }, 500);
        });
    },
    buytwo() {
      let msg = this.buyMsg.msg;
      let that = this;
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "请稍后...",
      });
      this.$http
        .post("/firm/v1/talent_center/payUploadResume", {
          reqType: "resume",
          id: msg.item.id,
          type: 1,
        })
        .then((res) => {
          this.$http
            .post("/firm/v1/talent_center/payUploadResume", {
              reqType: "resume",
              id: msg.item.id,
              type: 2,
            })
            .then(() => {
              Toast.clear();
              this.$toast("支付成功");
              setTimeout(() => {
                if (msg.type == 1) {
                  that.resumeEvaluationmeth(msg);
                } else if (msg.type == 2) {
                  that.resumematchmeth(msg);
                }
              }, 500);
            });
        });
    },
    //购买所有分析
    buyresumeall(msg, title, titlemsg) {
      this.buyMsg = {
        msg: msg,
        title: title,
        titlemsg: titlemsg,
      };
      this.showDialog = true;
    },
    //购买报告
    buymeth(msg) {
      if ((msg.item.final_sim * 10).toFixed(2) < 10) {
        this.$toast("该简历适配度过低，不建议查看！");
        return;
      }
      if (msg.type == 1) {
        let title = "购买能力分析报告",
          titlemsg = "使用余额3";
        this.buyresume(msg, title, titlemsg);
      } else if (msg.type == 2) {
        if (!msg.item.is_pay) {
          let title = "购买胜任力分析报告和能力分析",
            titlemsg = "完成简历能力分析，可以提高胜任力匹配的精确度哦~";
          this.buyresumeall(msg, title, titlemsg);
        } else {
          let title = "购买胜任力分析报告",
            titlemsg = "使用余额3";
          this.buyresume(msg, title, titlemsg);
        }
      }
    },
    //点击菜单
    clickmenu(msg) {
      switch (msg.state) {
        case 1: //能力分析
          if (msg.item.is_pay != 1) {
            this.buymeth(msg);
          } else {
            this.resumeEvaluationmeth(msg);
          }
          break;
        case 2: //胜任力匹配
          if (msg.item.is_pay1 != 1) {
            this.buymeth(msg);
          } else {
            this.resumematchmeth(msg);
          }
          break;
        case 3: //发起背调
          sessionStorage.setItem("sub2msgMobile", msg.item.mobile);
          this.$router.push("/backtotwo");
          break;
        case 4: //问卷调查
          this.$toast("该功能建设中，即将上线！");
          break;
        case 5: //邀请面试
          this.$toast("该功能建设中，即将上线！");
          break;
      }
    },
    //胜任力分析
    resumematchmeth(msg) {
      this.$router.push({
        path: "/resumematch",
        query: {
          resumeassistant: 1,
          allmsg: JSON.stringify({
            id: msg.item.id,
          }),
        },
      });
    },
    //能力分析
    resumeEvaluationmeth(msg) {
      this.$router.push({
        path: "/resumeEvaluation",
        query: {
          resumeassistant: 1,
          allmsg: JSON.stringify({
            assessment: msg.item.assessment,
            company: msg.item.work_company,
            estimated_salary: msg.item.estimated_salary,
            expect_salary: msg.item.expect_salary,
            job: msg.item.job,
            name: msg.item.name,
            sex: msg.item.sex,
            work_salary: msg.item.work_salary,
          }),
        },
      });
    },
    //展示提示内容
    showtip(e) {
      if (!e) return;
      this.tipmsg = e;
      this.show = true;
    },
    //点击tab
    clicktab(e) {
      this.tableindex = e;
      this.pageno = 0;
      this.sub_id = this.tablist[e].id;
      this.getGWMsg();
    },
    //获取岗位对应的简历信息
    getGWMsg() {
      if (!this.sub_id) return;
      this.$refs.loades
        .loadhttpes("/firm/v1/talent_center/talentLists", {
          reqType: "resume",
          pageno: ++this.pageno,
          limit: 9,
          is_recommend: 0,
          sub_id: this.sub_id,
        })
        .then((res) => {
          this.allmsg = res.arraymsg;
        });
    },
    loaddom() {
      this.getGWMsg();
    },
    //获取当前岗位(所有)
    getgangwei() {
      this.$http
        .post("/firm/v1/Resume/subscribeLists", {
          pageno: 1,
          reqType: "resume",
          limit: 15,
          type: 1, //1-开启 2-关闭 不传查询全部
        })
        .then((res) => {
          let listmsg = JSON.parse(res.data).data.subscribreLists;
          this.listmsg = [];
          if (listmsg.length < 1) {
            this.ishavemsg = false;
          }
          listmsg.forEach((element) => {
            this.tablist.push({
              name: element.theme,
              id: element.id,
            });
          });
          this.sub_id = this.tablist[0].id;
          this.getGWMsg();
        });
    },
  },
};
</script>
<style scoped>
.temclass {
  color: rgba(254, 89, 3, 0.75);
  display: flex;
  align-items: center;
}
.upclass >>> .van-popup {
  height: 65%;
  width: 90%;
  border-radius: 0.1rem;
  overflow: auto;
}
.addgangwei {
  width: 1.3rem;
  height: 1.3rem;
  text-align: center;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0.3rem;
  bottom: 2rem;
  padding: 0.18rem;
  background: #ffffff;
  box-shadow: 0rem 0.06rem 0.3rem 0rem rgba(144, 147, 153, 0.23);
  border-radius: 50%;
  font-size: 0.22rem;
  font-weight: 400;
  color: #606266;
  line-height: 0.31rem;
}
.js5 {
  border-radius: 0.1rem;
  color: white;
  width: 2.22rem;
  padding: 0.14rem 0;
  text-align: center;
  margin-right: 0.3rem;
  background: linear-gradient(90deg, #ff8d35, #ff5f18);
}
.js4 {
  border-radius: 0.1rem;
  color: #909399;
  border: 0.01rem solid #c0c4cc;
  width: 2.2rem;
  padding: 0.13rem 0;
  text-align: center;
  margin-right: 0.3rem;
}
.js3 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.35rem;
  margin-top: 0.2rem;
}
.js2 {
  text-align: center;
  font-size: 0.28rem;
  padding: 0.1rem 0;
  color: #606266;
}
.liclass >>> .van-cell::after {
  border: none;
}
.liyouall {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.liyouclass2 {
  width: 30%;
}
.liyouclass {
  background-color: #f6f6f7;
  color: #909399;
  width: 30%;
  text-align: center;
  font-size: 0.28rem;
  padding: 0.18rem 0;
  margin-bottom: 0.1rem;
}
.zjsclass {
  margin-top: 0.3rem;
  padding: 0 0.3rem;
}
.js1 {
  text-align: center;
  padding: 0.3rem;
  font-size: 0.35rem;
  font-weight: bold;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #f2f2f2;
}
.tipclass >>> .van-popup {
  padding: 0.1rem 0.3rem;
  border-radius: 0.1rem;
  overflow: hidden;
}
.mm18 {
  width: 0.26rem;
  margin-left: 0.2rem;
}
.mm17 {
  width: 0.26rem;
  margin-right: 0.2rem;
}
.mm16 {
  display: flex;
  align-items: center;
}
.mm15 {
  position: absolute;
  width: 0.53rem;
  top: -0.3rem;
  right: 0;
}
.mm13 .mm14 {
  margin-right: 0;
}
.mm13 {
  display: flex;
  align-items: center;
}
.mm13 img {
  width: 0.2rem;
  margin-left: 0.06rem;
  margin-right: 0.08rem;
}
.mm11 {
  text-align: center;
  position: relative;
  white-space: nowrap;
}
.mm12 {
  width: 0.32rem;
  margin-bottom: 0.1rem;
}
.yesclass {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 0.6rem;
}
.mm10 {
  font-size: 0.26rem;
  font-weight: 400;
  color: #909399;
  line-height: 0.26rem;
  display: flex;
  align-items: center;
}
.mm10 > :first-child {
  margin-right: 0.1rem;
}
.mm9 {
  font-size: 0.26rem;
  font-weight: 400;
  color: rgba(254, 89, 3, 0.75);
  line-height: 0.26rem;
  display: flex;
  align-items: center;
}
.mm9 img {
  width: 0.26rem;
  margin-left: 0.1rem;
}
.mm8 {
  margin-top: 0.32rem;
  padding: 0 0.28rem;
  padding-top: 0.32rem;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #efefef;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mm7 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.24rem;
  font-weight: 400;
  color: #606266;
  line-height: 0.34rem;
  margin-top: 0.66rem;
}
.mm6 {
  font-size: 0.38rem;
  font-weight: 500;
  color: rgba(254, 89, 3, 0.75);
  line-height: 0.41rem;
  text-align: center;
}
.mm6 > :nth-child(2) {
  font-size: 0.26rem;
  margin-top: 0.1rem;
}
.mm5 {
  font-size: 0.23rem;
  font-weight: 400;
  color: #909399;
  line-height: 0.31rem;
}
.mm4 {
  font-size: 0.23rem;
  font-weight: 400;
  color: #909399;
  line-height: 0.31rem;
  margin-top: 0.1rem;
  margin-bottom: 0.06rem;
}
.mm3 {
  font-size: 0.38rem;
  font-weight: 500;
  color: #303133;
  line-height: 0.45rem;
}
.mm2 {
  display: flex;
  align-items: center;
}
.mm2 > img {
  width: 1rem;
  margin-right: 0.2rem;
}
.mm1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.36rem;
  padding: 0 0.32rem;
}
.topmsg {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.28rem;
  font-size: 0.24rem;
  font-weight: 400;
  color: #909399;
  line-height: 0.31rem;
}
.themsgclass {
  margin: 0.28rem 0.26rem;
  background-color: #ffffff;
  padding-top: 0.16rem;
  padding-bottom: 0.34rem;
  position: relative;
  box-shadow: 0rem 0.02rem 0.86rem 0rem rgba(0, 0, 0, 0.03);
  border-radius: 0.15rem;
}
.vantab {
  margin-top: 0.02rem;
}
.tipbottom {
  position: fixed;
  bottom: 1rem;
  font-size: 0.28rem;
  font-weight: 400;
  color: #909399;
  line-height: 0.4rem;
  width: 100%;
  text-align: center;
}
.nomsgclass {
  margin-top: 1rem;
}
.buttonclass {
  font-size: 0.28rem;
  font-weight: 500;
  color: #606266;
  line-height: 0.48rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.6rem;
}
.buttonclass > :first-child {
  padding: 0.18rem 0;
  width: 3.08rem;
  border-radius: 0.42rem;
  border: 0.02rem solid #909399;
  text-align: center;
  margin-right: 0.2rem;
}
.buttonclass > :nth-child(2) {
  text-align: center;
  padding: 0.2rem 0;
  color: #ffffff;
  width: 3.08rem;
  background: linear-gradient(90deg, #ff5f18 0%, #ff8d35 100%);
  border-radius: 0.42rem;
}
.no1 {
  text-align: center;
  font-size: 0.28rem;
  font-weight: 500;
  color: #303133;
  line-height: 0.4rem;
  margin-top: 1.14rem;
  margin-bottom: 0.4rem;
}
.no2 {
  text-align: center;
  font-size: 0.28rem;
  font-weight: 500;
  color: #909399;
  line-height: 0.4rem;
}
.nomsgclassimg {
  text-align: center;
}
.nomsgclassimg img {
  width: 2.5rem;
}
</style>
